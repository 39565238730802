import React from "react"
import css from "./index.module.scss"
import fontType from "../../../helpers/fontType.module.scss"
import Grid from "../../Grid"
import Button from "../../Button"

const WorksWidget = () => {
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.text}>
        <p className={css.subtitle}>Improve referral success</p>
        <h2 className={css.title}>Boost engagement with referral nudges</h2>
      </div>
      <ul className={css.items}>
        <li>
          <img
            src={"//cdn.referralcandy.com/webimages/howitworks/poprocks.gif"}
            alt="Referral Widget"
            height="300"
            width="300"
          />
          <div className={css.feature_header}>
            <p className={fontType.b1}>Referral Widget</p>
          </div>
          <div className={css.feature_body}>
            <p>
              Pops up in the corner to enrol customers into your referral
              program
            </p>
          </div>
        </li>
        <li>
          <img
            src={"//cdn.referralcandy.com/webimages/howitworks/candyjar.gif"}
            alt="Embedded Signup Form"
            height="300"
            width="300"
          />
          <div className={css.feature_header}>
            <p className={fontType.b1}>Embedded Signup Form</p>
          </div>
          <div className={css.feature_body}>
            <p>Make your referral program part of the shopping experience</p>
          </div>
        </li>
        <li>
          <img
            src={"//cdn.referralcandy.com/webimages/howitworks/lollipop.gif"}
            alt="Post Purchase Popup"
            height="300"
            width="300"
          />
          <div className={css.feature_header}>
            <p className={fontType.b1}>Post Purchase Popup</p>
          </div>
          <div className={css.feature_body}>
            <p>
              Beautifully timed for when your customers are most likely to refer
            </p>
          </div>
        </li>
      </ul>
      <Button
        purpose="primary"
        href="https://my.referralcandy.com/signup"
        target="_blank"
      >
        Engage Your Customers
      </Button>
    </Grid>
  )
}

export default WorksWidget
