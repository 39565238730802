import React, { PureComponent } from "react"
import classnames from "classnames"
import css from "./index.module.scss"
import fontType from "../../../helpers/fontType.module.scss"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import Grid from "../../Grid"
import Button from "../../Button"

class WorksRewards extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      badge: 1,
    }
  }
  timeout = null

  handleClick = (badge, e) => {
    e.preventDefault()
    this.setState({ badge: badge })
  }

  scheduleNext = () => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const newBadge = this.state.badge !== 4 ? this.state.badge + 1 : 1
      this.setState({ badge: newBadge })
      this.scheduleNext()
    }, 5000)
  }

  componentDidMount() {
    this.scheduleNext()
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  renderBadges = (mobile, data) => {
    return (
      <div className={mobile ? css.nav_mobile : css.nav}>
        <ul className={css.menu}>
          <li>
            <a
              href="#state1"
              className={classnames(css.badge, {
                [css.selected]: this.state.badge === 1,
              })}
              onMouseDown={(e) => this.handleClick(1, e)}
              onTouchStart={(e) => this.handleClick(1, e)}
              onTouchEnd={(e) => e.preventDefault()}
              onClick={(e) => e.preventDefault()}
            >
              <div className={css.logo}>
                <Img
                  fixed={data.percentage.childImageSharp.fixed}
                  alt="Referralcandy how referrals work - referralcandy custom referral rewards percentage discount coupon ecommerce referral program app"
                />
              </div>
              <div className={css.blocktext}>
                <p>Percentage Discount</p>
                <p className={`${fontType.b3} ${css.subtext}`}>
                  30% off next purchase
                </p>
              </div>
              <div className={css.singletext}>
                <p>Percentage Discount</p>
              </div>
            </a>
          </li>
          <li>
            <a
              href="#state2"
              className={classnames(css.badge, {
                [css.selected]: this.state.badge === 2,
              })}
              onMouseDown={(e) => this.handleClick(2, e)}
              onTouchStart={(e) => this.handleClick(2, e)}
              onTouchEnd={(e) => e.preventDefault()}
              onClick={(e) => e.preventDefault()}
            >
              <div className={css.logo}>
                <Img
                  fixed={data.dollar.childImageSharp.fixed}
                  alt="Referralcandy how referrals work - referralcandy custom referral rewards cash discount dollar discount ecommerce referral program app"
                />
              </div>
              <div className={css.blocktext}>
                <p>Dollar Discount</p>
                <p className={`${fontType.b3} ${css.subtext}`}>
                  $20 off next purchase
                </p>
              </div>
              <div className={css.singletext}>
                <p>Dollar Discount</p>
              </div>
            </a>
          </li>
          <li>
            <a
              href="#state3"
              className={classnames(css.badge, {
                [css.selected]: this.state.badge === 3,
              })}
              onMouseDown={(e) => this.handleClick(3, e)}
              onTouchStart={(e) => this.handleClick(3, e)}
              onTouchEnd={(e) => e.preventDefault()}
              onClick={(e) => e.preventDefault()}
            >
              <div className={css.logo}>
                <Img
                  fixed={data.cash.childImageSharp.fixed}
                  alt="Referralcandy how referrals work - referralcandy custom referral rewards cash payout cash rewards ecommerce referral program app"
                />
              </div>
              <div className={css.blocktext}>
                <p>Cash</p>
                <p className={`${fontType.b3} ${css.subtext}`}>$5 in cash</p>
              </div>
              <div className={css.singletext}>
                <p>Cash</p>
              </div>
            </a>
          </li>
          <li>
            <a
              href="#state4"
              className={classnames(css.badge, {
                [css.selected]: this.state.badge === 4,
              })}
              onMouseDown={(e) => this.handleClick(4, e)}
              onTouchStart={(e) => this.handleClick(4, e)}
              onTouchEnd={(e) => e.preventDefault()}
              onClick={(e) => e.preventDefault()}
            >
              <div className={css.logo}>
                <Img
                  fixed={data.gift.childImageSharp.fixed}
                  alt="Referralcandy how referrals work - referralcandy custom referral rewards customised rewards gift"
                />
              </div>
              <div className={css.blocktext}>
                <p>Custom Gift</p>
                <p className={`${fontType.b3} ${css.subtext}`}>Free shipping</p>
              </div>
              <div className={css.singletext}>
                <p>Custom Gift</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    )
  }

  render() {
    const data = this.props.data

    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        {this.renderBadges(false, data)}
        <div className={css.text}>
          <h2>Choose your rewards</h2>
          <p>
            Customize your rewards for referring customers and referred friends.
            Choose from store discounts, cash payouts and custom rewards.
          </p>
          <Button
            purpose="primary"
            href="https://my.referralcandy.com/signup"
            target="_blank"
          >
            Set Up Your Rewards
          </Button>
        </div>
        {this.renderBadges(true, data)}
      </Grid>
    )
  }
}

const query = graphql`
  query {
    percentage: file(
      relativePath: {
        eq: "images/badge_logos/Referralcandy-how-referrals-work-referralcandy-custom-referral-rewards-percentage-discount-coupon-ecommerce-referral-program-app-percentage@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 42, height: 42) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    dollar: file(
      relativePath: {
        eq: "images/badge_logos/Referralcandy-how-referrals-work-referralcandy-custom-referral-rewards-cash-discount-dollar-discount-ecommerce-referral-program-app-dollar@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 42, height: 42) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    cash: file(
      relativePath: {
        eq: "images/badge_logos/Referralcandy-how-referrals-work-referralcandy-custom-referral-rewards-cash-payout-cash-rewards-ecommerce-referral-program-app-cash@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 42, height: 26) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    gift: file(
      relativePath: {
        eq: "images/badge_logos/Referralcandy-how-referrals-work-referralcandy-custom-referral-rewards-customised-rewards-gift-gift@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 39, height: 42) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <WorksRewards data={data} {...props} />}
  />
)
