import React, { PureComponent } from "react"
import css from "./index.module.scss"

import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import Grid from "../../Grid"
import Button from "../../Button"

class WorksTrack extends PureComponent {
  render() {
    const data = this.props.data

    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        <div className={css.image}>
          <Img
            fluid={data.track.childImageSharp.fluid}
            alt="Referralcandy how referrals work - referral marketing data analytics referral dashboard referral sales"
          />
        </div>
        <h2 className={css.title}>Watch your referral sales grow</h2>
        <p className={css.subtitle}>
          Track your referral revenue and traffic, see your top referrers and
          what they are saying about you on their social media
        </p>
        <Button
          className={css.button}
          purpose="primary"
          href="https://my.referralcandy.com/signup"
          target="_blank"
        >
          Get Referral Sales
        </Button>
      </Grid>
    )
  }
}

const query = graphql`
  query {
    track: file(
      relativePath: {
        eq: "images/how_it_works/Referralcandy-how-referrals-work-referral-marketing-data-analytics-referral-dashboard-referral-sales-track@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <WorksTrack data={data} {...props} />}
  />
)
