import React, { PureComponent } from "react"
import classnames from "classnames"
import css from "./index.module.scss"
import fontType from "../../../helpers/fontType.module.scss"
import Grid from "../../Grid"
import Button from "../../Button"

import BRANDING_IMAGE_1 from "../../../assets/images/how_it_works/branding/branding-1.png"
import BRANDING_IMAGE_2 from "../../../assets/images/how_it_works/branding/branding-2.png"
import BRANDING_IMAGE_3 from "../../../assets/images/how_it_works/branding/branding-3.png"
import BRANDING_IMAGE_4 from "../../../assets/images/how_it_works/branding/branding-4.png"

import ImageHolder from "./ImageHolder"

class WorksBranding extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      toggle: css.show,
      brandingImage: BRANDING_IMAGE_1,
      temp: BRANDING_IMAGE_1, // Temp image is stored here for transition to work smoothly
      mobile: false,
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ mobile: window.innerWidth <= 639 })
  }

  handleClick = (image, e) => {
    e.preventDefault()
    if (this.state.mobile) {
      this.setState({ brandingImage: image })
    } else {
      this.setState({ toggle: css.hide, temp: image })
    }
  }

  render() {
    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        <div className={css.text}>
          <p className={css.subtitle}>Fully customizable branding</p>
          <h2>Brand your emails and pages</h2>

          <ul className={css.listing}>
            <li key="1">
              <a
                autoFocus={false}
                href="#state1"
                onClick={(e) => this.handleClick(BRANDING_IMAGE_1, e)}
                className={classnames({
                  [css.badge]: this.state.brandingImage === BRANDING_IMAGE_1,
                  [css.no_badge]: this.state.brandingImage !== BRANDING_IMAGE_1,
                })}
              >
                <p className={`${fontType.b1} ${css.steptext}`}>
                  1. Upload Logo and Banner
                </p>
              </a>
            </li>
            {this.state.brandingImage === BRANDING_IMAGE_1 &&
            this.state.mobile ? (
              <ImageHolder image={BRANDING_IMAGE_1} />
            ) : null}
            <li key="2">
              <a
                href="#state2"
                onClick={(e) => this.handleClick(BRANDING_IMAGE_2, e)}
                className={classnames({
                  [css.badge]: this.state.brandingImage === BRANDING_IMAGE_2,
                  [css.no_badge]: this.state.brandingImage !== BRANDING_IMAGE_2,
                })}
              >
                <p className={`${fontType.b1} ${css.steptext}`}>
                  2. Choose brand color and font
                </p>
              </a>
            </li>
            {this.state.brandingImage === BRANDING_IMAGE_2 &&
            this.state.mobile ? (
              <ImageHolder image={BRANDING_IMAGE_2} />
            ) : null}
            <li key="3">
              <a
                href="#state3"
                onClick={(e) => this.handleClick(BRANDING_IMAGE_3, e)}
                className={classnames({
                  [css.badge]: this.state.brandingImage === BRANDING_IMAGE_3,
                  [css.no_badge]: this.state.brandingImage !== BRANDING_IMAGE_3,
                })}
              >
                <p className={`${fontType.b1} ${css.steptext}`}>
                  3. Update your copy
                </p>
              </a>
            </li>
            {this.state.brandingImage === BRANDING_IMAGE_3 &&
            this.state.mobile ? (
              <ImageHolder image={BRANDING_IMAGE_3} />
            ) : null}
            <li key="4">
              <a
                href="#state4"
                onClick={(e) => this.handleClick(BRANDING_IMAGE_4, e)}
                className={classnames({
                  [css.badge]: this.state.brandingImage === BRANDING_IMAGE_4,
                  [css.no_badge]: this.state.brandingImage !== BRANDING_IMAGE_4,
                })}
              >
                <p className={`${fontType.b1} ${css.steptext}`}>
                  4. We’ll do the rest
                </p>
              </a>
            </li>
            {this.state.brandingImage === BRANDING_IMAGE_4 &&
            this.state.mobile ? (
              <ImageHolder image={BRANDING_IMAGE_4} />
            ) : null}
          </ul>

          <Button
            purpose="primary"
            href="https://my.referralcandy.com/signup"
            target="_blank"
            className={css.button}
          >
            Build Your Brand
          </Button>
        </div>
        <div className={css.image}>
          <img
            src={this.state.brandingImage}
            alt="Branding"
            onTransitionEnd={() =>
              this.setState({
                toggle: css.show,
                brandingImage: this.state.temp,
              })
            }
            className={this.state.toggle}
            height="300"
            width="300"
          />
        </div>
      </Grid>
    )
  }
}

export default WorksBranding
