const INTEGRATION_LISTINGS = [
  {
    logo: "shopify",
    name: "Shopify",
    description: "One-click connection",
    alt:
      "Referralcandy how referrals work - referralcandy shopify integration ecommerce referral program app",
  },
  {
    logo: "woocommerce",
    name: "WooCommerce",
    description: "Plugin connection",
    alt:
      "Referralcandy how referrals work - referralcandy woocommerce integration ecommerce referral program app",
  },
  {
    logo: "bigcommerce",
    name: "BigCommerce",
    description: "One-click connection",
    alt:
      "Referralcandy how referrals work - referralcandy bigcommerce integration ecommerce referral program app",
  },
  {
    logo: "magento",
    name: "Magento",
    description: "Email connection",
    alt:
      "Referralcandy how referrals work - referralcandy magento integration ecommerce referral program app",
  },
  {
    logo: "recharge",
    name: "ReCharge",
    description: "One-click connection",
    alt:
      "Referralcandy how referrals work - referralcandy recharge integration ecommerce referral program app",
  },
  {
    logo: "custom",
    name: "Your custom store",
    description: "Email or custom connection",
    alt:
      "Referralcandy how referrals work - referralcandy custom store standalone no platform ecommerce referral program app",
  },
]

export default INTEGRATION_LISTINGS
