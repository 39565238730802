import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import css from "./index.module.scss"
import fontType from "../../../helpers/fontType.module.scss"
import Grid from "../../Grid"
import Button from "../../Button"

import { FaArrowRight } from "react-icons/fa"

const query = graphql`
  query {
    banner: file(
      relativePath: {
        eq: "images/how_it_works/how-referrals-work-Referralcandy-Referral-program-software-sets-up-in-10-minutes-referralcandy-features-how-it-works.png"
      }
    ) {
      childImageSharp {
        fixed(height: 350) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const WorksBanner = () => {
  const { banner } = useStaticQuery(query)
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.text}>
        <h1>
          Easy to set up <br />
          Easy to use
        </h1>

        <p className={fontType.b1}>
          Your customers could be making their first referrals in 10 minutes.
          Don’t believe it? Try launching ReferralCandy now.
        </p>
        <p>
          <Button
            purpose="primary"
            href="https://my.referralcandy.com/signup"
            target="_blank"
          >
            Launch Now &nbsp; <FaArrowRight />
          </Button>
        </p>
      </div>
      <div className={css.image}>
        <Img
          fixed={banner.childImageSharp.fixed}
          alt="how referrals work - Referralcandy Referral program software sets up in 10 minutes referralcandy features"
        />
      </div>
    </Grid>
  )
}

export default WorksBanner
