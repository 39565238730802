import React from "react"
import css from "./index.module.scss"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../../Grid"
import Button from "../../Button"

const query = graphql`
  query {
    mailchimp: file(
      relativePath: {
        eq: "images/integration_logos/referralcandy-integrations-mailchimp-email-marketing-embed-referral-links-Referralcandy-how-referrals-work-mailchimp@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 33, height: 35) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    ga: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-how-referrals-work-referralcandy-integrations-mailchimp-analytics-google-referral-analytics-integration-traffic-data-ga@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 32, height: 35) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    google: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-how-referrals-work-referralcandy-integrations-google-remarketing-ads-google-adwords-google@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 36, height: 33) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    fb_pixel: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-how-referrals-work-referralcandy-integrations-facebook-pixel-facebook-retargeting-pixel-fb_pixel@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 31, height: 35) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    adroll: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-how-referrals-work-referralcandy-integrations-adroll-retargeting-ads-adwords-adroll@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 39, height: 35) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    klaviyo: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-how-referrals-work-referralcandy-integrations-klaviyo-referral-marketing-email-links-klaviyo@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 52, height: 35) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    marketing: file(
      relativePath: { eq: "images/how_it_works/marketing-how-it-works.png" }
    ) {
      childImageSharp {
        fixed(height: 340) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`

const WorksMarketing = () => {
  const {
    mailchimp,
    ga,
    google,
    fb_pixel,
    adroll,
    klaviyo,
    marketing,
  } = useStaticQuery(query)
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.text}>
        <p className={css.subtitle}>Even more marketing value</p>
        <h2>Connect your marketing apps</h2>
        <p>
          ReferralCandy connects to your marketing stack to give you more
          insights on customers, sales and traffic.
        </p>
        <div className={css.logo_container}>
          <div className={css.logo}>
            <Img
              fixed={mailchimp.childImageSharp.fixed}
              alt="referralcandy integrations mailchimp email marketing embed referral links  - Referralcandy how referrals work"
            />
          </div>
          <div className={css.logo}>
            <Img
              fixed={ga.childImageSharp.fixed}
              alt="Referralcandy how referrals work - referralcandy integrations mailchimp google analytics referral analytics integration traffic data"
            />
          </div>
          <div className={css.logo}>
            <Img
              fixed={google.childImageSharp.fixed}
              alt="Referralcandy how referrals work - referralcandy integrations google remarketing ads google adwords"
            />
          </div>
          <div className={css.logo}>
            <Img
              fixed={fb_pixel.childImageSharp.fixed}
              alt="Referralcandy how referrals work - referralcandy integrations facebook pixel facebook retargeting pixel"
            />
          </div>
          <div className={css.logo}>
            <Img
              fixed={adroll.childImageSharp.fixed}
              alt="Referralcandy how referrals work - referralcandy integrations adroll retargeting ads adwords"
            />
          </div>
          <div className={css.logo}>
            <Img
              fixed={klaviyo.childImageSharp.fixed}
              alt="Referralcandy how referrals work - referralcandy integrations klaviyo referral marketing email links"
            />
          </div>
        </div>
        <div>
          <Button
            purpose="primary"
            href="https://my.referralcandy.com/signup"
            target="_blank"
          >
            Level Up Your Marketing
          </Button>
        </div>
      </div>
      <div className={css.image}>
        <Img fixed={marketing.childImageSharp.fixed} alt="Banner" />
      </div>
    </Grid>
  )
}

export default WorksMarketing
