import React from "react"
import Layout from "../components/Layout"

import WorksBanner from "../components/Sections/WorksBanner"
import WorksTestimonial from "../components/Sections/WorksTestimonial"
import WorksConnect from "../components/Sections/WorksConnect"
import WorksRewards from "../components/Sections/WorksRewards"
import WorksMarketing from "../components/Sections/WorksMarketing"
import WorksWidget from "../components/Sections/WorksWidget"
import WorksBranding from "../components/Sections/WorksBranding"
import WorksTrack from "../components/Sections/WorksTrack"
import WorksFraud from "../components/Sections/WorksFraud"
import GradientBackground from "../components/Sections/GradientBackground"

const metadata = {
  title:
    "How Referral Marketing Works | ReferralCandy Customer Referral Marketing Platform",
  description:
    "How does ReferralCandy work? See how referral marketing works for your store and all the ReferralCandy features and integrations.",
}

// https://app.clubhouse.io/rc/story/16331/add-fitsmallbusiness-pixels-to-referralcandy
const FitSmallBusinessTracking = () => (
  <script
    dangerouslySetInnerHTML={{
      __html: `
(function() {
  var CONVERSION_TRACKING = {
    'fitsmallbusiness': 1
  };
  function initFitSmallBusinessConversionPixel() {
    var fitpixeliframe=document.createElement('iframe');
    fitpixeliframe.style.display="none";
    fitpixeliframe.src='https://app.fitsmallbusiness.com/track/landing/50';
    document.body.appendChild(fitpixeliframe);
  };
  function detectAdConversion() {
    var search = window.location.search || '';
    var utmSource = (search.match(/utm_source=(.*?)(&|$)/i) || [])[1];
    if (utmSource != null) {
      if (utmSource in CONVERSION_TRACKING) {
        initFitSmallBusinessConversionPixel();
      }
    }
  };
  detectAdConversion();
})()
              `,
    }}
  />
)

export default (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <WorksBanner />
      <GradientBackground>
        <WorksTestimonial />
      </GradientBackground>
      <WorksConnect />
      <WorksRewards />
      <WorksMarketing />
      <WorksWidget />
      <WorksBranding />
      <WorksTrack />
      <WorksFraud />
      <FitSmallBusinessTracking />
    </Layout>
  )
}
