import React, { PureComponent } from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import classnames from "classnames"
import Grid from "../../Grid"
import Button from "../../Button"

import css from "./index.module.scss"

const TESTIMONIAL = {
  key: "powder_city",
  quote:
    "It’s so easy to use. It’s the least labor intensive marketing task that we have. It runs itself. For virtually no labor, I get so many sales.",
  cite: "Nathan Smeltzer",
  position: "Owner, Powder City",
  url: "https://www.referralcandy.com/case-studies/powder-city/",
}

class WorksTestimonial extends PureComponent {
  render() {
    const data = this.props.data

    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        <div className={classnames(css.content)}>
          <div className={css.image}>
            <div className={css.picture}>
              <Img
                fluid={data[`picture_${TESTIMONIAL.key}`].childImageSharp.fluid}
                className={css.gatsby_image}
              />
            </div>
          </div>
          <blockquote className={css.quote}>
            <p className={css.quote_text}>{TESTIMONIAL.quote}</p>
            <footer>
              <div className={css.avatar_container}>
                <Img
                  fluid={
                    data[`avatar_${TESTIMONIAL.key}`].childImageSharp.fluid
                  }
                />
              </div>
              <cite>
                <p>{TESTIMONIAL.cite}</p>
                {TESTIMONIAL.position}
              </cite>
            </footer>
            <Button
              className={css.quote_button}
              purpose="secondary"
              href={TESTIMONIAL.url}
              target="_blank"
            >
              Read Case Study
            </Button>
          </blockquote>
        </div>
        <div className={css.images}>
          <div className={css.picture_mobile}>
            <Img
              fluid={data[`picture_${TESTIMONIAL.key}`].childImageSharp.fluid}
              className={css.gatsby_image}
            />
          </div>
        </div>
      </Grid>
    )
  }
}

const query = graphql`
  query {
    picture_powder_city: file(
      relativePath: {
        eq: "images/testimonials/Referralcandy-how-referrals-work-referralcandy-ad-preview-powder-city-example-picture-powder-city.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    avatar_powder_city: file(
      relativePath: {
        eq: "images/testimonials/Referralcandy-how-referrals-work-powder-city-endorses-referralcandy-referral-program-software-avatar-powder-city.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <WorksTestimonial data={data} {...props} />}
  />
)
