import React, { PureComponent } from "react"
import css from "./index.module.scss"

import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import Grid from "../../Grid"

class WorksFraud extends PureComponent {
  render() {
    const data = this.props.data

    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        <div className={css.image}>
          <Img
            fluid={data.fraud.childImageSharp.fluid}
            alt="Referralcandy how referrals work - referral marketing data analytics referral dashboard referral sales"
          />
        </div>
        <h2 className={css.title}>Protect against fraud and spam</h2>
        <p className={css.subtitle}>
          ReferralCandy informs you of suspicious activities so that you can
          review and deal with them accordingly to maximize your returns
        </p>
        {/* <Button
          className={css.button}
          purpose="primary"
          href="https://my.referralcandy.com/signup"
          target="_blank"
        >
          Get Referral Sales
        </Button> */}
      </Grid>
    )
  }
}

const query = graphql`
  query {
    fraud: file(relativePath: { eq: "images/how_it_works/fraud-center.png" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <WorksFraud data={data} {...props} />}
  />
)
