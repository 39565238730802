import React, { PureComponent } from "react"
import css from "./index.module.scss"
import fontType from "../../../helpers/fontType.module.scss"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import Grid from "../../Grid"
import Button from "../../Button"

import INTEGRATION_LISTINGS from "./IntegrationListings"

class WorksConnect extends PureComponent {
  renderIntegrationListings = (data) => {
    return INTEGRATION_LISTINGS.map((integration) => {
      return (
        <div className={css.int_container} key={integration.name}>
          <div className={css.logo}>
            <Img
              fixed={data[`${integration.logo}`].childImageSharp.fixed}
              alt={integration.alt}
            />
          </div>
          <div className={css.content}>
            <p className={`${fontType.b1} ${css.name}`}>{integration.name}</p>
            <p className={css.description}>{integration.description}</p>
          </div>
        </div>
      )
    })
  }

  render() {
    const data = this.props.data

    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        <div className={css.text}>
          <h2>Connect your store</h2>
          <p>
            ReferralCandy works with{" "}
            <a
              href="https://www.referralcandy.com/features/#integration"
              target="__blank"
              className={css.link}
            >
              every eCommerce store
            </a>
            , through app integrations or email connect. Ask and we’ll show you
            how easy it is to connect your store!
          </p>
          <Button
            purpose="primary"
            href="https://my.referralcandy.com/signup"
            target="_blank"
          >
            Get Started Now
          </Button>
        </div>
        <div className={css.listings}>
          {this.renderIntegrationListings(data)}
        </div>
      </Grid>
    )
  }
}

const query = graphql`
  query {
    shopify: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-how-referrals-work-referralcandy-shopify-integration-ecommerce-referral-program-app-shopify@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 30, height: 35) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    woocommerce: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-how-referrals-work-referralcandy-woocommerce-integration-ecommerce-referral-program-app-woocommerce@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 35, height: 21) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    bigcommerce: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-how-referrals-work-referralcandy-bigcommerce-integration-ecommerce-referral-program-app-bigcommerce@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    magento: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-how-referrals-work-referralcandy-magento-integration-ecommerce-referral-program-app-magento@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 35, height: 41) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    recharge: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-how-referrals-work-referralcandy-recharge-integration-ecommerce-referral-program-app-recharge@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 30, height: 36) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    custom: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-how-referrals-work-referralcandy-custom-store-standalone-no-platform-ecommerce-referral-program-app-custom@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 34, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <WorksConnect data={data} {...props} />}
  />
)
